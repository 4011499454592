<template>
  <el-main>
    <div class="condition-fill">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item class="group-name" label="人群名称" prop="title" label-width="80px">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <div class="title">
          <span></span>
          人群需同时满足以下特征
        </div>
        <div class="condition-con">
          <div class="condition-box">
            <div class="condition-fill-list">
              <GroupEmpty v-if="!group_fill_list.length"></GroupEmpty>
              <div v-for="(item, index) in group_fill_list" :key="index">
                <GroupFill :groupInfo="info.data" :cardLevel="cardLevel" :val_key="item.key" @deleteGroup="deleteGroup" ref="group_fill" :type="item.type"></GroupFill>
              </div>
            </div>
            <div class="result-num">
              <span>预估人数</span>
              <el-input v-model="groupNum" disabled></el-input>
              <span>人</span>
              <el-button @click="getGroupNum" type="primary">计算新人群</el-button>
            </div>
          </div>
          <div class="condition-select">
            <div class="titles">筛选特征组成人群</div>
            <div class="feature-list">
              <div :class="['select-class-hover', item.is_select ? 'select-class' : '']" v-for="(item, index) in group_list" :key="index" @click="selectCondition(index)">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <Preservation @preservation="save">
      <router-link to="/user/userGroup" slot="return" style="margin-right: 10px">
        <el-button>返回</el-button>
      </router-link>
    </Preservation>
  </el-main>
</template>
<script>
import Preservation from '@/components/preservation';
import GroupEmpty from './components/groupEmpty';
import GroupFill from './components/groupFill';
export default {
  components: {
    Preservation,
    GroupEmpty,
    GroupFill,
  },
  data() {
    return {
      id: '',
      form: {
        title: '',
      },
      groupNum: '',
      cardLevel: [],
      info: {},
      group_list: [
        { name: '店铺有支付', is_select: !1, key: 'store_pay' },
        { name: '店铺无支付', is_select: !1, key: 'store_nopay' },
        { name: '店铺有加购物车', is_select: !1, key: 'add_cart' },
        { name: '店铺无加购物车', is_select: !1, key: 'add_nocart' },
        { name: '店铺有访问', is_select: !1, key: 'store_visit' },
        { name: '店铺无访问', is_select: !1, key: 'store_novisit' },
        { name: '有效购买次数', is_select: !1, key: 'buy_times' },
        { name: '有效购买金额', is_select: !1, key: 'buy_amount' },
        { name: '店铺有下单', is_select: !1, key: 'order_submit' },
        { name: '店铺无下单', is_select: !1, key: 'noorder_submit' },
        { name: '无退款订单数', is_select: !1, key: 'norefund' },
        { name: '每笔单价', is_select: !1, key: 'costprice' },
        { name: '店铺会员', is_select: !1, key: 'vip' },
        { name: '会员等级', is_select: !1, key: 'member_level' },
        { name: '来源渠道', is_select: !1, key: 'comfrom' },
        { name: '储值余额', is_select: !1, key: 'balance' },
      ],
      group_fill_list: [],
      rules: {
        title: [
          {
            required: true,
            message: '必填',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    let id = this.$route.query.id;
    if (id) {
      this.id = id;
      this.getInfo();
    }
  },
  methods: {
    //获取编辑信息
    getInfo() {
      this.$axios.post(this.$api.member.groupInfo, { id: this.id }).then(res => {
        if (res.code == 0) {
          let group_list = this.group_list;
          let info = res.result.info;
          for (let i in group_list) {
            for (let y in info.data) {
              if (group_list[i].key == y) {
                group_list[i].is_select = !0;
                this.group_fill_list.push({
                  type: Number(i),
                  key: y,
                });
                if (y == 'member_level') this.getCardLevel();
              }
            }
          }
          this.form.title = info.title;
          this.info = info;
        }
      });
    },
    //选择条件
    selectCondition(index) {
      let group_list = this.group_list;
      if (group_list[index].is_select) {
        group_list[index].is_select = !1;
        this.group_fill_list = this.group_fill_list.filter(item => item.type != index);
      } else {
        if (index == 13 && !this.cardLevel.length) this.getCardLevel();
        this.group_fill_list.push({
          type: index,
        });
        group_list[index].is_select = !0;
      }
    },
    //获取会员等级
    getCardLevel() {
      this.$axios.post(this.$api.user.CardLevel).then(res => {
        if (res.code == 0) {
          this.cardLevel = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取计算人群
    getGroupNum() {
      let group_fill_list = this.group_fill_list;
      let data = [];
      let params = {};
      for (let i in group_fill_list) {
        data.push(this.$refs.group_fill[i].validate());
      }
      if (data.length && data.every(item => item.rules == !0)) {
        data.map(item => (params[item.key] = item.value));
        this.$axios.post(this.$api.member.getGroupNum, { data: params }).then(res => {
          if (res.code == 0) {
            this.groupNum = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    save() {
      let group_fill_list = this.group_fill_list;
      let data = [];
      this.$refs.form.validate(valid => {
        if (valid) {
          for (let i in group_fill_list) {
            data.push(this.$refs.group_fill[i].validate());
          }
          if (data.length && data.every(item => item.rules == !0)) {
            let params = {
              data: {},
              title: this.form.title,
            };
            if (this.id) params.id = this.id;
            data.map(item => (params.data[item.key] = item.value));
            this.$axios.post(this.id ? this.$api.member.groupEdit : this.$api.member.addGroup, params).then(res => {
              if (res.code == 0) {
                this.$message.success(this.id ? '编辑成功' : '添加成功');
                this.$router.push('/user/userGroup');
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
    //删除条件
    deleteGroup(type) {
      this.group_fill_list = this.group_fill_list.filter(item => item.type != type);
      this.group_list[type].is_select = !1;
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  height: 100%;
  font-size: 14px;
  padding-bottom: 80px;
  .condition-fill {
    height: 100%;
    margin-right: 35px;
    border-radius: 2px;
    .el-form {
      height: 100%;
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        span {
          width: 4px;
          height: 14px;
          background: #409eff;
          margin-right: 4px;
        }
      }
      .group-name .el-input {
        width: 560px;
      }
      .condition-con {
        display: flex;
        flex: 1;
        height: 0;
        .condition-box {
          flex: 1;
          background: #f7f8fa;
          border: 1px solid #e8e8e8;
          display: flex;
          flex-direction: column;
          margin-right: 35px;
          .condition-fill-list {
            position: relative;
            flex: 1;
            overflow-y: auto;
            padding: 15px;
          }
          .result-num {
            height: 59px;
            background: #f7f8fa;
            border-top: 1px solid #e8e8e8;
            padding-left: 20px;
            z-index: 3;
            display: flex;
            align-items: center;
            .el-input {
              width: 280px;
              margin: 0 7px 0 9px;
            }
            /deep/ .el-input__inner {
              background: none;
            }
            .el-button {
              margin-left: 23px;
            }
          }
        }
        .condition-select {
          width: 420px;
          background: #f7f8fa;
          border: 1px solid #e8e8e8;
          border-radius: 2px;
          display: flex;
          flex-direction: column;
          .titles {
            line-height: 60px;
            background: #f1f3f6;
            border-bottom: 1px solid #e8e8e8;
            font-size: 16px;
            text-align: center;
            color: #409eff;
          }
          .feature-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-height: calc(100% - 60px);
            overflow-y: auto;
            padding-top: 20px;
            div {
              width: 160px;
              height: 40px;
              line-height: 40px;
              background: #ffffff;
              border: 1px solid #e8e8e8;
              border-radius: 2px;
              margin-bottom: 14px;
              text-align: center;
              cursor: pointer;
              &:nth-child(odd) {
                margin-right: 20px;
              }
            }
            .select-class {
              border: 1px solid #409eff;
              color: #409eff;
            }
            .select-class-hover:hover {
              background: #edf4ff;
            }
          }
        }
      }
    }
  }
}
</style>
